<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <form action="" @submit.prevent="onSubmit()">
                    <px-card>
                            <b-row class="mb-2">
                                <b-col md="2" class="font-weight-bold">
                                    Source
                                </b-col>
                                <b-col md="9">
                                    <b-form-input size="sm" type="text" name="name" v-model="input.source" placeholder="source name" required></b-form-input>
                                </b-col>
                            </b-row><hr>
                            <b-row>
                                <b-col md="2"></b-col>
                                <b-col>
                                    <small title="Show Macros" v-b-toggle.simpleaccordion-1 variant="default" class="">Add Params</small>
                                    <b-collapse id="simpleaccordion-1" visible accordion="myaccordion" role="tabpanel">
                                    <b-row class="link col-md-12" >
                                    <small v-for="(value, index) in macros" :key="index" style="background:#e6e6e6;">
                                        <label class="p-1 link_txt" @click="addParams(value)" >{{value}}</label>
                                    </small>
                                </b-row></b-collapse>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col md="2" class="font-weight-bold">
                                    URL
                                </b-col>
                                <b-col md="9">
                                    <b-form-textarea size="sm" type="url" name="url" :state="validate_url" v-model="input.url" placeholder="affiliate URL" rows="3"  @input="checkButton()" required></b-form-textarea>
                                    <b-form-invalid-feedback :state="validate_url">The given URL has an invalid character</b-form-invalid-feedback>
                                </b-col>
                            </b-row><hr>
                            <b-row class="mt-3 text-center">
                                <b-col>
                                    <b-button type="submit" variant="primary">{{edit_affiliate?'Edit Affiliate':'Add New Affiliate'}}</b-button>
                                </b-col>
                            </b-row>
                    </px-card>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import ManagerController from "../../../services/managerController";
export default{
    name:'CreateAffiliate',
    props:{create_affiliate_modal:Boolean,edit_affiliate:Boolean,affiliate_data:Object},
    data(){
        return{
            input:{source:'',url:''},
            collapse:false,
            macros:['event','clickid'],
        }
    },
    computed:{
        validate_url(){
            return this.validateUrl(this.input.url)
        }
    },
    mounted(){
        if(this.edit_affiliate){
            this.input = this.affiliate_data
        }
    },
    methods:{
        async onSubmit(){
            if(this.validateUrl(this.input.url)){
            let payload = {'source':this.input.source,'url':this.input.url}
            var response
            if(this.edit_affiliate){
                console.log('edit affiliate')
                var p = { "affid": this.input._id, "changeBody": payload}
                response= await ManagerController.editAffiliate(p)
            }
            else{
                response= await ManagerController.createAffiliate(payload)
            }
            if(response.result){
                this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
                this.$emit('affiliatemodal')
            }
            else{
                this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
            }
        }
        },
        validateUrl(url){
            var pattern = /^(ftp|http|https):\/\/[^ "]+$/;
            return pattern.test(url);
        },
        addParams(data){
            var str3 = "{" + data + "}";
            let url_text = str3;
            this.input.url = this.input.url.concat(url_text)
            this.checkButton()
        },
        checkButton(){
            var params = ['event','clickid']
            for(let e in params) {
                // match value from array and remove it
                if( this.input.url.match(new RegExp('{'+params[e]+'}')) ){
                delete params[e]
                } 
            this.macros = params
        }
      },
    }
}

</script>