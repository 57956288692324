<template>
  <div>
    <Breadcrumbs main="" title="Affiliate" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <b-button
            size=""
            variant="primary"
            class="btn"
            @click="create_affiliate_modal = true"
            >Create New Affiliate +</b-button
          >
          <b-row class="mt-3">
                <b-col class="col-md-6 col-12">
                  <b-input-group>
                    <b-input-group-prepend >
                      <span class="input-group-text search-bg"><i class="icofont icofont-search-alt-2"></i></span>
                    </b-input-group-prepend>
                    <b-form-input
                      class="col-md-5"
                      v-model="filter"
                      placeholder="Type to Search"
                    ></b-form-input>
                  </b-input-group>
                </b-col>

                <b-col class="text-right col-md-6 col-12">
                  <b-form-group
                    label-cols="10"
                    label="Per page"
                    class="mb-0"
                  >
                    <b-form-select
                      class="col-md-10"
                      v-model="perPage"
                      :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="col-md-12" v-if="loading">
                <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
              <div v-else class="table-responsive datatable-vue text-left">
                <b-table
                  show-empty
                  stacked="md"
                  :items="items"
                  :filter="filter"
                  :current-page="currentPage"
                  :fields="tablefields"
                  :per-page="perPage"
                  @filtered="onFiltered"
                >
                <template #table-caption>Affiliate List.</template>

                <template #cell(source)="data">
                  <b class="text-info">{{ data.item.source }}</b>
                </template>
                <template #cell(createdAt)="data">
                  <b class="text-info">{{ changeDateFormate(data.item.createdAt) }}</b>
                </template>
                <template #cell(status)="data">
                  <div class="media-body icon-state switch-outline">
                      <label class="switch">
                      <input type="checkbox" :checked=data.item.status @change="statusUpdate(data.item)"><span class="switch-state" :class="data.item.status?'bg-success':'bg-danger'"></span>
                      </label>
                  </div>
                </template>
                <template #cell(action)="data">
                  <feather class="text-danger" type="trash-2" title="Delete" @click="deleteAffiliate(data.item._id)"></feather>
                  <feather class="text-info ml-2" type="edit" title="Edit" @click="editAffiliate(data.item)"></feather>                  
                </template>
                </b-table>
              </div>

              <b-col md="6" class="mt-3 p-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  class="my-0"
                ></b-pagination>
              </b-col>
        </div>
      </div>
    </div>
    <b-modal id="modal-affiliate" size="lg" title="Affiliate" class="theme-modal" @hidden="closeModal()" v-model="create_affiliate_modal" no-close-on-backdrop hide-footer>
        <create-affiliate :create_affiliate_modal="create_affiliate_modal" @affiliatemodal="closeModal" :edit_affiliate="edit_affiliate" :affiliate_data="affiliate_data"></create-affiliate>
        <template #modal-footer>
        <b-button @click="closeModal()" color="danger">Close</b-button>
        </template>
      </b-modal>
  </div>
</template>




<script>
import moment from "moment";
import CreateAffiliate from "./CreateAffiliate.vue"
import ManagerController from "../../../services/managerController";
export default {
  name: "Affiliate",
  components:{CreateAffiliate},
  data(){
    return{
        create_affiliate_modal:false,
        edit_affiliate:false,
        affiliate_data:{},
        loading:false,
        items:[],
        totalRows:0,
        filter:null,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 50],
        tablefields: [
                { key: "source", label: "Source", sortable: true },
                { key: "url", label: "Url", sortable: true },
                { key: "status", label: "Status", sortable: true },
                { key: "createdAt", label: "CreatedAt", sortable: true },
                { key: "action", label: "Action", sortable: false },
        ],
    }
  },
  mounted(){
    this.getAffiliates()
  },
  methods:{
    async getAffiliates(){
      this.loading = true;
            this.items = []
            let response = await ManagerController.getAffiliate()
            if (response.result) {
                this.items = response.data.reverse();
                this.totalRows = this.items.length;
            }
            this.loading = false;
    },
    async deleteAffiliate(id){
          if(confirm('Are you sure to delete this Affiliate?')){
          this.loading = true;
          let payload = {'affid': id}
          let response = await ManagerController.deleteAffiliate(payload);
          if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
          }
          this.getAffiliates()
        }
        },
    async statusUpdate(data){
      var s = true
      if(data.status == true){
          s = false
      }
      if (data.status == false) {
        data.status = true;
      } else {
        data.status = false;
      }
      var changepayload = {"status": s}
      var p = { "affid": data._id, "changePayload": changepayload}
      let response = await ManagerController.editAffiliate(p)
      if(response.result){
          this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
      }
      else{
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
        this.getAffiliates()
      }
    },
    editAffiliate(data){
      this.create_affiliate_modal = true
      this.affiliate_data = data;
      this.edit_affiliate = true;
    },
    closeModal(){
        this.create_affiliate_modal = false
        this.edit_affiliate = false;
        this.getAffiliates();
    },
    changeDateFormate(d) {
      return moment(d).format("D/M/YYYY, HH A");
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
    },
  }
};
</script>